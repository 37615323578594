// 与post相关的公共方法

import size from "lodash/size";
import pick from "lodash/pick";
import map from "lodash/fp/map";
import find from "lodash/fp/find";
import concat from "lodash/concat";
import orderBy from "lodash/orderBy";
import filter from "lodash/fp/filter";
import useUser from "../store/user";
import { storeToRefs } from "pinia";
// import  userStore  from '../store/user'
// import { setLocalStorage } from '/utils/save.js'
import uniqBy from "lodash/uniqBy";
import { getMyPostSync, getPostExtraSync, getMyLikeSync, getHomeListSync } from "/utils/api";
import cloneDeep from "lodash/cloneDeep";
// import useUser from '../store/user'
// import {storeToRefs} from 'pinia'

/**
 * 获取视频列表
 * 分2步：（1）先查ff_post和播放量，渲染出画面，（2）再查视频的额外信息，用于点击视频播放后查看的内容
 * 参数：
 * @param page 分页的页码
 * @param type {*} 查什么类型的视频 - my | myPrivacy | like | foryou | follow
 * @param isSelf {*} 是否是本人 - 0 | 1 - 如果不是本人，就要传这个人的username
 * @param username {} 用户名
 * @param postId {} 分享的视频id
 */
export const getPostList = (args) => {
    // 参数
    const page = args?.page || 1;
    const type = args.type;
    const isSelf = args.isSelf;
    const profId = args?.profId;
    const postId = args?.postId;
    const noPostData = useState("noPostData");
    const noLikeData = useState("noPostData");
    const ispageplus = useState("ispageplus");
    const userPostLoading = useState("userPostLoading");
    const userLikeLoading = useState("userLikeLoading");
    const currentUserType = useCookie("currentUserType")
    const hasMultiType = useCookie("hasMultiType")
    if (type == 'my' && userPostLoading.value && !args?.postId) return
    if (type == 'like' && userLikeLoading.value) return
    // 第一步
    let request;
    if (type == "my" || type == "myPrivacy") {
        // 查本人的视频
        args.noLoading ? '' : (userPostLoading.value = true)
        let reqParameters = {
            page,
            type,
            isSelf,
            profId,
            limit: args.limit || 10
        }
        postId ? (reqParameters.postId = postId) : ''
        if (hasMultiType.value) {
            reqParameters.isSelfie = typeof currentUserType.value == 'undefined' ? 1 : [0, 1].includes(parseInt(currentUserType.value)) ? currentUserType.value : 1
        } else {
            reqParameters.isSelfie = 1
        }
        request = getMyPostSync(reqParameters);
    }
    if (type == "like") {
        args.noLoading ? '' : (userLikeLoading.value = true)
        let reqParameters = {
            page,
            isSelf,
            profId,
            limit: args.limit || 10
        }
        if (hasMultiType.value) {
            reqParameters.isSelfie = typeof currentUserType.value == 'undefined' ? 1 : [0, 1].includes(parseInt(currentUserType.value)) ? currentUserType.value : 1
        } else {
            reqParameters.isSelfie = 1
        }
        postId ? (reqParameters.postId = postId) : ''
        request = getMyLikeSync(reqParameters);
    }
    request
        .then((res) => {
            if (res.data?.length > 0) {
                ispageplus.value = true;
                if (res.data.length < 10 && !args?.postId) {
                    if (type == "my" || type == "myPrivacy") {
                        noPostData.value = true
                    }
                    if (type == "like") {
                        noLikeData.value = true
                    }
                } else if (res.data.length >= 10) {
                    if (type == "my" || type == "myPrivacy") {
                        noPostData.value = false
                    }
                    if (type == "like") {
                        noLikeData.value = false
                    }
                }
                let tempList;
                let list;
                let listPin;
                
                if (type == "my") {
                    // 正常流
                    if (isSelf) {
                        list = useState("myPost");
                    } else {
                        list = useState("userPost");
                    }
                    //合并数据
                    if (args?.postId) {
                        for (let i = 0; i < (list.value || []).length; i++) {
                            if (parseInt(args?.postId) == parseInt((list.value || [])[i].id)) {
                                list.value.splice(i, 1)
                                break
                            }
                        }
                        list.value = concat(res.data, cloneDeep(list.value || []));
                    } else {
                        list.value = concat(cloneDeep(list.value || []), res.data);
                    }
                    // list.value = filter((item) => {
                    //     return item.pin == 0;
                    // })(res.data);
                    // // 置顶
                    // listPin = useState("myPostPin");
                    // let pin = filter((item) => {
                    //     return item.pin == 1;
                    // })(res.data);
                    // listPin.value = orderBy(pin, ["pinTime"], ["desc"]);
                } else {
                    list = useState(`${type}Post`);
                    list.value = concat(cloneDeep(list.value || []), res.data);
                    // res.data = concat(list.value || [], res.data);
                    // list.value = concat(list.value, resCopy);
                }

                //如果不是第一页，将后续请求视频的与之前的合并。必须要有这一步，否则数据混乱。
                // if (page == 1 && !args?.postId) {
                //     if (type == "my") {
                //         // 正常流
                //         if (isSelf) {
                //             list = useState("myPost");
                //         } else {
                //             list = useState("userPost");
                //         }

                //         list.value = filter((item) => {
                //             return item.pin == 0;
                //         })(res.data);
                //         // 置顶
                //         listPin = useState("myPostPin");
                //         let pin = filter((item) => {
                //             return item.pin == 1;
                //         })(res.data);
                //         listPin.value = orderBy(pin, ["pinTime"], ["desc"]);
                //         "lis", list, type;
                //     } else {
                //         list = useState(`${type}Post`);
                //         tempList = res.data;
                //         list.value = res.data;
                //     }
                // } else {
                //     if (type == "my") {
                //         const resCopy = res.data;
                //         // 正常流
                //         if (isSelf) {
                //             list = useState("myPost");
                //         } else {
                //             list = useState("userPost");
                //         }
                //         //合并数据
                //         if (args?.postId) {
                //             for (let i = 0; i < (list.value || []).length; i++) {
                //                 if (parseInt(args?.postId) == parseInt((list.value || [])[i].id)) {
                //                     console.log(i)
                //                     list.value.splice(i, 1)
                //                     break
                //                 }
                //             }
                //             list.value = concat(res.data, list.value || []);
                //         } else {
                //             list.value = concat(list.value || [], res.data);
                //         }
                //         list.value = filter((item) => {
                //             return item.pin == 0;
                //         })(res.data);
                //         // 置顶
                //         listPin = useState("myPostPin");
                //         let pin = filter((item) => {
                //             return item.pin == 1;
                //         })(res.data);
                //         listPin.value = orderBy(pin, ["pinTime"], ["desc"]);
                //     } else {
                //         const resCopy = res.data;
                //         list = useState(`${type}Post`);
                //         res.data = concat(list.value || [], res.data);
                //         list.value = concat(list.value, resCopy);
                //     }
                // }
                
                // // 第二步 - 这批视频的id存在了后端redis中，通过requestId取回
                // if (size(res.data) && type == "like") {
                //     //userListLoading.value = true;
                //     getPostExtraSync({
                //         // 'userId': res.data[0]?.userId,
                //         requestId: res.requestId,
                //     }).then((res) => {
                //         //userListLoading.value = false;
                //         // 放回list
                //         list.value = fillPostExtra(list.value, res.data);
                //         if (type == "my") {
                //             listPin.value = fillPostExtra(listPin.value, res.data);
                //         }
                //     });
                // }
                list.value = uniqBy(list.value, "id");
            } else {
                //若没有数据且为第一页，则list.value = []，否则没有视频时的提示组件无法正常显示
                if (page == 1) {
                    let list;
                    if (type == "my") {
                        if (isSelf) {
                            list = useState("myPost");
                        } else {
                            list = useState("userPost");
                        }
                    } else {
                        list = useState(`${type}Post`);
                    }
                    list.value = res.data;
                    list.value = uniqBy(list.value, "id");
                }
                ispageplus.value = false;
            }
            args.callback && args.callback(res);
        })
        .finally(() => {
            if (type == 'my') {
                args.noLoading ? '' : (userPostLoading.value = false)
            }
            if (type == 'like') {
                args.noLoading ? '' : (userLikeLoading.value = false)
            }
        });
};

// const fillPostExtra = (list, extraData) => {
//     list = map((item) => {
//         const extraOne = find((extraItem) => {
//             return extraItem && extraItem?.id && item && item?.id && extraItem.id == item.id;
//         })(extraData);
//         return {
//             ...item,
//             ...extraOne,
//         };
//     })(list);
//     return list;
// };

// const noDataTips = useState('noDataTips');
// 查询首页视频
export const getHomeList = (args) => {
    const data = pick(args, ["type", "page", "postId", "getPopular", "callback", 'filter[highQuality]', 'limit']);
    const currentUserType = useCookie("currentUserType")
    const hasMultiType = useCookie("hasMultiType")
    if (hasMultiType.value) {
        data.isSelfie = typeof currentUserType.value == 'undefined' ? 1 : [0, 1].includes(parseInt(currentUserType.value)) ? currentUserType.value : 1
    } else {
        data.isSelfie = 1
    }
    getHomeListSync(data).then((res) => {
        // const list = useState(`${data.type}Home`);
        // list.value = concat(list.value || [], res.data);
        // userStore.$patch((state) => {
        // 	state.homeList = list.value
        // })
        let arr = [];
        const list = useState(`${data.type}Home`);
        if (data.postId) {
            arr = concat(res.data, list.value || []);
        } else {
            arr = concat(list.value || [], res.data);
        }
        list.value = uniqBy(arr, "id");
        // const store = userStore()
        // store.$state.homeList = list.value
        const homeList = useState("homeList");
        const homeFollowList = useState("homeFollowList");
        if (data.type == "foryou") {
            homeList.value = list.value;
        } else if (data.type == "follow") {
            homeFollowList.value = list.value;
        }
        // setLocalStorage('homeList', JSON.stringify(list.value));
        localStorage.setItem("homeList", JSON.stringify(homeList.value));
        localStorage.setItem("homeFollowList", JSON.stringify(homeFollowList.value));
        typeof data.callback == "function" ? data.callback(res) : "";
        // if(){

        // }
    });
};

// export const getHomeList = (args) => {
//     new Promise((resolve, reject) => {
//         var timerCount = 0;
//         var timer = window.setInterval(function () {
//             timerCount += 1;
//             var userinfo = useCookie("userinfo");
//             if (userinfo.value || timerCount > 120) {
//                 userinfo.value ? resolve() : reject();
//                 clearInterval(timer);
//             }
//         }, 500);
//     })
//         .then(() => {

//             const data = pick(args, ["type", "page", "postId", "getPopular", "callback"]);
//             getHomeListSync(data).then((res) => {

//                 let arr = [];
//                 const list = useState(`${data.type}Home`);
//                 if (data.postId) {
//                     arr = concat(res.data, list.value || []);
//                 } else {
//                     arr = concat(list.value || [], res.data);
//                 }
//                 list.value = uniqBy(arr, "id");

//                 const userStore = useUser();
//                 const { homeFollowList, homeList, currItem } = storeToRefs(userStore);
//                 if (data.type == "foryou") {
//                     homeList.value = list.value;
//                 } else if (data.type == "follow") {
//                     homeFollowList.value = list.value;
//                 }
//                 localStorage.setItem("homeList", JSON.stringify(homeList.value));
//                 localStorage.setItem("homeFollowList", JSON.stringify(homeFollowList.value));
//                 typeof data.callback == "function" ? data.callback(res) : "";
//             });
//         })
//         .catch((err) => {
//             console.log(err);
//         });
// };
